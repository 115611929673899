import path from 'path';
import React from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';

import Layout from '../components/layout';

import Social  from '../pages/widgets/social';

const BlogHome = ({ children, data }) => {

  const { edges: posts } = data.allMarkdownRemark;

  return (
    <div className=''>

      <Helmet title="Blog | SwitchSmith">
        <link rel="canonical" href="https://switchsmith.com/blog" />
      </Helmet>

      <div className="row pt3">
        <div className="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
        <div className="col-xs-10 col-sm-10 col-md-2 col-lg-8">
          <div className="flex items-baseline justify-between">
            <h1 className="mt3">Blog</h1>
            <Link to={'/rss.xml'}>RSS</Link>
          </div>
        </div>
      </div>

      <div className='pt3'>

        {posts
          .filter(post => post.node.frontmatter.title.length > 0)
          .map(({ node: post }) => {
            const absPath = post.fileAbsolutePath;
            const slug = 'blog/' + path.basename(absPath, path.extname(absPath));
            return (

              <div className="row pb4-ns" key={post.id}>
                <div className="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
                <div className="col-xs-10 col-sm-10 col-md-2 col-lg-8">

                  <div className="mt3 pa3 shadow-1">
                    <Link className="link mid-gray hover-mid-gray"
                      to={slug}>
                      <h2>{post.frontmatter.title}</h2>
                      <h5>{post.frontmatter.date}</h5>
                      <div className="pt3">
                        <Social shareUrl={'shareUrl'} announcement={"announcement"} />
                      </div>
                      <p className="mt3">{post.excerpt}</p>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}

      </div>
    </div>
  );
}

const query = graphql`
  query {
    allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/(blog).*/"}},
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fileAbsolutePath
          excerpt(pruneLength: 200)
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            announcement
          }
        }
      }
    }
  }
`;

const BlogHomePage = () => (
  <Layout>
    <StaticQuery query={query}>
      {(data) => (
        <BlogHome data={data}/>
      )}
    </StaticQuery>
  </Layout>
)

export default BlogHomePage;