import React from 'react';

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  TwitterIcon,
  FacebookIcon,
  LinkedinIcon,
} from 'react-share';

export default ({ shareUrl, announcement }) => (
  <div className="flex items-start">

    <TwitterShareButton
      url={shareUrl}
      title={announcement}
      className="">
      <TwitterIcon
        size={32}
        round />
    </TwitterShareButton>

    <FacebookShareButton
      url={shareUrl}
      quote={announcement}
      className="pl2">
      <FacebookIcon
        size={32}
        round />
    </FacebookShareButton>

    <LinkedinShareButton
      url={shareUrl}
      title={announcement}
      windowWidth={750}
      windowHeight={600}
      className="pl2">
      <LinkedinIcon
        size={32}
        round />
    </LinkedinShareButton>

  </div>
)